import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserCredential } from "firebase/auth";
import {
  Stack,
  Button,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  TextField,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import AuthService from "../../services/AuthService";
import { Roles } from "../../types/users/Roles";
import { getJwtClaims } from "../../utils/utils";
import {
  forgotPasswordQuestion,
  backToLogin,
  logIn,
  emailLabel,
  emailPlaceholder,
  enterEmail,
  enterPassword,
  passwordLabel,
  passwordPlaceholder,
  rememberMe,
  forgotPassword,
  requestPasswordReset,
  notAuthorisedOrUserConfigError,
  failedLogin,
  successForgotPassword,
  failedForgotPassword,
} from "../../services/Messages";
import {
  MessageComponent,
  MessageProps,
} from "../../components/MessageComponent";
import { HandleForm } from "../../components/HandleForm";
import { LoadingComponent } from "../../components/LoadingComponent";

export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [showForgotPasswordForm, setShowForgotPasswordForm] =
    useState<boolean>(false);
  const formInitialState = {
    loginEmail: "",
    forgotPasswordEmail: "",
    password: "",
    rememberMe: "",
  };

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const loginForm = () => {
    return (
      <form onSubmit={onSubmit}>
        <Stack spacing={1}>
          <FormControl>
            <FormLabel htmlFor="loginEmail">{emailLabel}</FormLabel>
            <TextField
              autoFocus
              id="loginEmail"
              type="email"
              variant="outlined"
              required
              aria-describedby={enterEmail}
              placeholder={emailPlaceholder}
              name="loginEmail"
              onChange={onChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">{passwordLabel}</FormLabel>
            <TextField
              id="password"
              type="password"
              variant="outlined"
              required
              aria-describedby={enterPassword}
              placeholder={passwordPlaceholder}
              name="password"
              onChange={onChange}
            />
          </FormControl>
          <FormControlLabel
            control={<Switch onChange={onChange} name="rememberMe" />}
            label={rememberMe}
          />
          <Button variant="contained" type="submit">
            {logIn}
          </Button>
        </Stack>
      </form>
    );
  };

  const forgotPasswordForm = () => {
    return (
      <form onSubmit={onSubmit}>
        <Stack spacing={1}>
          <FormControl>
            <FormLabel htmlFor="forgotPasswordEmail">
              {forgotPassword}
            </FormLabel>
            <TextField
              id="forgotPasswordEmail"
              variant="outlined"
              required
              aria-describedby={enterEmail}
              placeholder={enterEmail}
              name="forgotPasswordEmail"
              onChange={onChange}
            />
          </FormControl>
          <Button variant="contained" type="submit" onClick={() => onSubmit}>
            {requestPasswordReset}
          </Button>
        </Stack>
      </form>
    );
  };

  const getContent = () => {
    return (
      <Box sx={{ mx: 5, my: 5 }}>
        <Stack spacing={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            {logIn}
          </Typography>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {!showForgotPasswordForm && (
            <>
              {loginForm()}
              <Button variant="text" onClick={onToggle}>
                {forgotPasswordQuestion}
              </Button>
            </>
          )}
          {showForgotPasswordForm && (
            <>
              {forgotPasswordForm()}
              <Button variant="text" onClick={onToggle}>
                {backToLogin}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    );
  };

  const onToggle = () => {
    setMessage({ message: "", variant: "info" });
    setShowForgotPasswordForm(!showForgotPasswordForm);
  };

  const handleSubmit = async () => {
    try {
      if (showForgotPasswordForm) {
        await submitForgotPasswordForm();
      } else {
        await submitLoginForm();
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        message: showForgotPasswordForm ? failedForgotPassword : failedLogin,
        variant: "error",
      });
    }
  };

  const submitLoginForm = async () => {
    setLoading(true);
    const map = new Map(Object.entries(values));
    const email: string = map.get("loginEmail") as string;
    const password: string = map.get("password") as string;
    const rememberMe: boolean = (map.get(`rememberMe`) as string) === "on";

    const credential: UserCredential = await AuthService.logIn(
      email,
      password,
      rememberMe
    );

    const token: string = await credential.user.getIdToken();
    const claims = getJwtClaims(token);

    if (claims.role === Roles.student) {
      await AuthService.logOut();
      setMessage({
        message: notAuthorisedOrUserConfigError,
        variant: "error",
      });
      return;
    }
    setLoading(false);
    navigate("/dashboard");
  };

  const submitForgotPasswordForm = async () => {
    setLoading(true);
    const map = new Map(Object.entries(values));
    const email: string = map.get("forgotPasswordEmail") as string;
    await AuthService.forgotPassword(email);
    setLoading(false);
    setMessage({ message: successForgotPassword, variant: "success" });
  };

  const { onChange, onSubmit, values } = HandleForm(
    handleSubmit,
    formInitialState
  );

  return (
    <>
      {loading || authContext.loading ? (
        <LoadingComponent />
      ) : authContext.user ? (
        <Navigate to="/" replace />
      ) : (
        getContent()
      )}
    </>
  );
};
