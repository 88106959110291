import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import {
  failedToGetTerms,
  dashboardTitle,
  departmentsTitle,
  termsLabel,
  topicsLabel,
} from "../../services/Messages";
import { Department } from "../../types/departments/Department";
import { Topic } from "../../types/topics/Topic";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { TermsTable } from "./components/TermsTable";
import { LoadingComponent } from "../../components/LoadingComponent";
import {
  MessageComponent,
  MessageProps,
} from "../../components/MessageComponent";

export const TermsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [department, setDepartment] = useState<Department>({
    id: 0,
    name: "",
    years: [],
  });
  const [topic, setTopic] = useState<Topic>({
    id: 0,
    name: "",
    years: [],
    numTerms: 0,
    newOrModified: false,
  });

  let params = useParams();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async (
      departmentId: string,
      topicId: string,
      token: string
    ) => {
      let [department, topicResponse] = await Promise.all([
        ApiService.getDepartment(departmentId, token),
        ApiService.getTopic(topicId, token),
      ]);

      setDepartment(department);
      setTopic(topicResponse.data);
    };
    if (!authContext.loading && authContext.userCredential) {
      fetchData(
        params.deptId!,
        params.topicId!,
        authContext.userCredential.token
      )
        .then(() => {
          setMessage({ message: "", variant: "info" });
          setLoading(false);
        })
        .catch((e) => {
          setMessage({ message: failedToGetTerms, variant: "error" });
          setLoading(false);
        });
    }
  }, [
    params.deptId,
    params.topicId,
    authContext.loading,
    authContext.user,
    authContext.userCredential,
  ]);

  const getContent = () => {
    return (
      <Box sx={{ m: 2 }}>
        <Stack spacing={3}>
          <Breadcrumbs
            crumbs={[
              { name: dashboardTitle, path: "/dashboard" },
              { name: departmentsTitle, path: "/depts" },
              {
                name: `${department.name} ${topicsLabel}`,
                path: `/depts/${department.id}/topics`,
              },
              { name: `${topic.name} ${termsLabel}`, path: "#" },
            ]}
          />

          <Typography variant="h4" gutterBottom>
            {topic.name} Terms
          </Typography>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}

          <TermsTable topicId={params.topicId!} user={authContext.user!} />
        </Stack>
      </Box>
    );
  };

  return <>{loading ? <LoadingComponent /> : getContent()}</>;
};
