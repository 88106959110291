import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import AuthService from "../../services/AuthService";
import ApiService from "../../services/ApiService";
import { Constants } from "../../Constants";
import {
  MessageComponent,
  MessageProps,
} from "../../components/MessageComponent";
import {
  backToDashboard,
  confirmNewPassword,
  doChangePassword,
  enterCurrentPassword,
  enterNewPassword,
  failedChangePassword,
  failedChangePasswordLength,
  failedChangePasswordMatch,
  successChangePassword,
  userSettingsFor,
} from "../../services/Messages";
import { HandleForm } from "../../components/HandleForm";
import { LoadingComponent } from "../../components/LoadingComponent";

export const UserProfilePage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const initialFormState = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const getContent = () => {
    return (
      <>
        {changePasswordForm()}
        <Button
          color="secondary"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={navigateToDashboard}
        >
          {backToDashboard}
        </Button>
      </>
    );
  };

  const changePasswordForm = () => {
    return (
      <>
        <Typography variant="h5">{doChangePassword}</Typography>
        <form onSubmit={onSubmit}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Stack spacing={1}>
              <TextField
                id="currentPassword"
                label={enterCurrentPassword}
                type="password"
                variant="outlined"
                required
                name="currentPassword"
                onChange={onChange}
              />
              <TextField
                id="newPassword"
                label={enterNewPassword}
                type="password"
                variant="outlined"
                required
                name="newPassword"
                onChange={onChange}
              />
              <TextField
                id="confirmNewPassword"
                label={confirmNewPassword}
                type="password"
                variant="outlined"
                required
                name="confirmNewPassword"
                onChange={onChange}
              />
              <Button variant="contained" type="submit">
                {doChangePassword}
              </Button>
            </Stack>
          </FormControl>
        </form>
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      const map = new Map(Object.entries(values));
      const currentPassword: string = map.get("currentPassword") as string;
      const newPassword: string = map.get("newPassword") as string;
      const confirmNewPassword: string = map.get(
        "confirmNewPassword"
      ) as string;

      if (newPassword !== confirmNewPassword) {
        setMessage({ message: failedChangePasswordMatch, variant: "error" });
        return;
      }
      if (newPassword.length < 6) {
        setMessage({ message: failedChangePasswordLength, variant: "error" });
        return;
      }

      setLoading(true);
      await AuthService.changePassword(
        authContext.user,
        currentPassword,
        newPassword
      );
      await clearMustChangePassword();
      setMessage({ message: successChangePassword, variant: "success" });
      setLoading(false);
    } catch (e) {
      setMessage({ message: failedChangePassword, variant: "error" });
      setLoading(false);
    }
  };

  const clearMustChangePassword = async () => {
    const token = await authContext.user?.getIdToken();
    await ApiService.deleteSelfData(
      Constants.MUSTCHANGEPASSWORDSETTINGNAME,
      token
    );
  };

  const { onChange, onSubmit, values } = HandleForm(
    handleSubmit,
    initialFormState
  );

  const navigateToDashboard = () => {
    navigate("/");
  };
  return (
    <Box sx={{ mx: 5, my: 5 }}>
      <Stack spacing={2}>
        <Typography variant="h4" gutterBottom>
          {userSettingsFor}&nbsp;
          {authContext.user
            ? authContext.user.displayName
              ? authContext.user.displayName
              : authContext.user.email
            : ""}
        </Typography>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            href={message.href}
            variant={message.variant}
          />
        )}
        {loading ? <LoadingComponent /> : getContent()}
      </Stack>
    </Box>
  );
};
