import { ChangeEvent, KeyboardEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { User } from "firebase/auth";
import axios from "axios";
import ApiService from "../../../services/ApiService";
import {
  invite,
  cancel,
  inviteTeacher,
  failedToInviteTeacher,
  emailLabel,
  displayNameLabel,
  departmentLabel,
  noDefaultDepartment,
  invalidEmailFormat,
  optional,
  enterRequiredValues,
} from "../../../services/Messages";
import { Department } from "../../../types/departments/Department";
import { validateEmail } from "../../../utils/utils";
import { LoadingComponent } from "../../../components/LoadingComponent";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { DatabaseUser } from "../../../types/users/DatabaseUser";

interface inviteUserModalProps {
  show: boolean;
  schoolId: string;
  departments: Array<Department>;
  user: User;
  onSubmitCallback: (user: DatabaseUser) => void;
  onCancelCallback: () => void;
}

export const InviteUserModal = ({
  show,
  schoolId,
  departments,
  user,
  onSubmitCallback,
  onCancelCallback,
}: inviteUserModalProps) => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [email, setEmail] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [departmentId, setDepartmentId] = useState<string>("");

  const onDepartmentChanged = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const departmentId = Number(event.target.value);
    const department = departments.find((d) => d.id === departmentId);
    if (department) {
      setDepartmentId(department.id.toString());
    } else {
      setDepartmentId("");
    }
  };

  const clearForm = () => {
    setEmail("");
    setDisplayName("");
    setDepartmentId("");
  };

  const getContent = () => {
    return (
      <FormControl fullWidth>
        <Stack spacing={2}>
          <TextField
            autoFocus
            label={emailLabel}
            id="email"
            type="email"
            value={email}
            variant="outlined"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label={displayNameLabel}
            id="displayName"
            value={displayName}
            variant="outlined"
            name="displayName"
            onChange={(e) => setDisplayName(e.target.value)}
          />

          <TextField
            value={departmentId.length === 0 ? "0" : departmentId}
            onChange={onDepartmentChanged}
            select
            label={`${departmentLabel} ${optional}`}
          >
            <MenuItem value="0">{noDefaultDepartment}</MenuItem>
            {departments.map((department, index) => {
              return (
                <MenuItem key={index} value={department.id}>
                  {department.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Stack>
      </FormControl>
    );
  };

  const addUser = async () => {
    setMessage({ message: "", variant: "info" });

    if (!validateEmail(email)) {
      setMessage({ message: invalidEmailFormat, variant: "error" });
      return;
    }
    if (displayName.trim().length === 0) {
      setMessage({ message: enterRequiredValues, variant: "error" });
      return;
    }
    const token = await user.getIdToken();
    const response = await ApiService.inviteTeacher(
      {
        schoolId: schoolId,
        email: email,
        displayName: displayName.trim(),
        departmentId: departmentId === "0" ? "" : departmentId,
      },
      token
    );
    const addedUser: DatabaseUser = {
      id: response.data["id"],
      email: response.data["email"],
      role: response.data["role"],
    };
    clearForm();
    onSubmitCallback(addedUser);
  };

  const onSubmit = async () => {
    try {
      setIsBusy(true);
      await addUser();
      setIsBusy(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setMessage({ message: error.response.data.error, variant: "error" });
      } else {
        setMessage({ message: failedToInviteTeacher, variant: "error" });
      }
      setIsBusy(false);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onCancel = () => {
    setMessage({ message: "", variant: "info" });
    clearForm();
    onCancelCallback();
  };

  return (
    <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancel}>
      <DialogTitle>{inviteTeacher}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ m: 1 }}>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {isBusy ? <LoadingComponent /> : getContent()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={isBusy} onClick={onCancel}>
          {cancel}
        </Button>
        <Button disabled={isBusy} onClick={onSubmit}>
          {invite}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
