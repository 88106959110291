import { User } from "firebase/auth";
import React from "react";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  addAnotherDefinition,
  addTermTitle,
  cancel,
  definitionsLabel,
  editTermTitle,
  enterRequiredValues,
  failedToAddTerm,
  failedToEditTerm,
  optional,
  removeDefinition,
  resourcesLabel,
  save,
  termLabel,
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { Term } from "../../../types/terms/Term";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";

interface AddOrEditTermModalProps {
  show: boolean;
  topicId: string;
  term: Term | null;
  user: User;
  onSubmitCallback: (term: Term, wasAdded: boolean) => void;
  onCancelCallback: () => void;
}

export const AddOrEditTermModal = ({
  show,
  topicId,
  term,
  user,
  onSubmitCallback,
  onCancelCallback,
}: AddOrEditTermModalProps) => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [termText, setTermText] = useState<string>("");
  const [definitions, setDefinitions] = useState<Array<string>>([]);
  const [resources, setResources] = useState<string>("");

  const isAdd = term == null;

  useEffect(() => {
    setMessage({ message: "", variant: "info" });

    if (term !== null) {
      setTermText(term.text);
      const td = term.definitions.map((d) => {
        return d.text;
      });
      setDefinitions(td);
      setResources(term.resources ?? "");
    } else {
      clearForm();
    }
  }, [term]);

  const onDefinitionsChanged = (event: ChangeEvent<HTMLInputElement>) => {
    let def: string = "def-";
    if (event.target.name.includes(def)) {
      let index: number = Number(event.target.name.substring(def.length));
      let defs = [...definitions];
      defs.splice(index, 1, event.target.value);
      setDefinitions(defs);
    }
  };

  const onAddDefinition = () => {
    setMessage({ message: "", variant: "info" });
    let defs = [...definitions];
    defs.push("");
    setDefinitions(defs);
  };

  const onDeleteDefinition = (index: number) => {
    setMessage({ message: "", variant: "info" });
    let defs = [...definitions];
    defs.splice(index, 1);
    setDefinitions(defs);
  };

  const onTermTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermText(event.target.value);
  };

  const onResourcesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResources(event.target.value);
  };

  const getContent = () => {
    return (
      <FormControl fullWidth>
        <FormLabel component="legend">{termLabel}</FormLabel>
        <TextField
          autoFocus
          variant="outlined"
          required
          value={termText}
          name="term"
          id="term"
          onChange={onTermTextChanged}
        />

        <FormLabel component="legend">{definitionsLabel}</FormLabel>
        <Grid container spacing={0.5}>
          {definitions.map((a, index) => {
            return (
              <React.Fragment key={`frag-${index}`}>
                <Grid size={{ xs: 9, sm: 9, md: 9 }} key={`row-${index}`}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    key={`def-${index}`}
                    name={`def-${index}`}
                    id={`def-${index}`}
                    value={a}
                    onChange={onDefinitionsChanged}
                  />
                </Grid>
                {index !== 0 && (
                  <Grid size={{ xs: 3, sm: 3, md: 3 }} key={`col-del-${index}`}>
                    <Button
                      variant="contained"
                      key={`button-del-${index}`}
                      onClick={() => onDeleteDefinition(index)}
                    >
                      {removeDefinition}
                    </Button>
                  </Grid>
                )}
                {index === 0 && (
                  <Grid
                    size={{ xs: 3, sm: 3, md: 3 }}
                    key={`col-add-another-${index}`}
                  >
                    <Button
                      variant="contained"
                      key={`button-add-another-${index}`}
                      onClick={onAddDefinition}
                      type="submit"
                    >
                      {addAnotherDefinition}
                    </Button>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        <FormLabel component="legend">{`${resourcesLabel} ${optional}`}</FormLabel>
        <TextField
          variant="outlined"
          value={resources}
          name="resources"
          id="resources"
          onChange={onResourcesChanged}
        />
      </FormControl>
    );
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onCancel = () => {
    setMessage({ message: "", variant: "info" });
    onCancelCallback();
  };

  const validateTerm = () => {
    if (termText.trim().length === 0) {
      setMessage({ message: enterRequiredValues, variant: "error" });
      return false;
    }

    const hasEmptyDefinitions = definitions.some((d) => d.trim() === "");
    if (hasEmptyDefinitions) {
      setMessage({ message: enterRequiredValues, variant: "error" });
      return false;
    }
    return true;
  };

  const clearForm = () => {
    setTermText("");
    // Always show one definition
    setDefinitions([""]);
    setResources("");
  };

  const addTerm = async () => {
    setMessage({ message: "", variant: "info" });

    if (!validateTerm()) {
      return;
    }

    let token = await user.getIdToken();
    const response = await ApiService.addTerm(
      {
        topicId: topicId,
        term: termText,
        definitions: definitions,
        resources: resources.length === 0 ? undefined : resources,
      },
      token
    );

    const addedDefinition: Term = {
      id: response.data["id"],
      text: response.data["text"],
      definitions: response.data["definitions"].map((d: string) => {
        return {
          text: d,
        };
      }),
      numQuestions: 0,
      resources: response.data["resources"],
      newOrModified: true,
    };
    clearForm();
    onSubmitCallback(addedDefinition, isAdd);
  };

  const editTerm = async () => {
    setMessage({ message: "", variant: "info" });

    if (!validateTerm()) {
      return;
    }

    let token = await user.getIdToken();
    let response = await ApiService.updateTerm(
      term!.id.toString(),
      {
        topicId: topicId,
        term: termText,
        definitions: definitions,
        resources: resources.length === 0 ? undefined : resources,
      },
      token
    );

    let editedTerm: Term = {
      id: response.data["id"],
      text: response.data["text"],
      definitions: response.data["definitions"].map((d: string) => {
        return {
          text: d,
        };
      }),
      numQuestions: term!.numQuestions,
      resources: response.data["resources"],
      newOrModified: true,
    };
    clearForm();
    onSubmitCallback(editedTerm, isAdd);
  };

  const onSubmit = async () => {
    try {
      setIsBusy(true);
      isAdd ? await addTerm() : await editTerm();
      setIsBusy(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setMessage({ message: error.response.data.error, variant: "error" });
      } else {
        setMessage({
          message: isAdd ? failedToAddTerm : failedToEditTerm,
          variant: "error",
        });
      }
      setIsBusy(false);
    }
  };

  return (
    <Dialog
      open={show}
      onKeyUp={onKeyUp}
      onClose={onCancel}
      disableRestoreFocus
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{isAdd ? addTermTitle : editTermTitle}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ m: 1 }}>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {isBusy ? <LoadingComponent /> : getContent()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={isBusy} onClick={onCancel}>
          {cancel}
        </Button>
        <Button disabled={isBusy} onClick={onSubmit}>
          {save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
