import { User } from "firebase/auth";
import { KeyboardEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  cancel,
  deleteTopicWithTerms,
  deleteTopicNoTerms,
  doDelete,
  failedToDeleteTopic,
  topicNameDoesNotMatch,
  topicLabel,
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { Topic } from "../../../types/topics/Topic";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";

interface DeleteTopicModalProps {
  show: boolean;
  user: User;
  topic: Topic | null;
  onCancelCallback: () => void;
  onSubmitCallback: (topic: Topic) => void;
}

export const DeleteTopicModal = ({
  show,
  user,
  topic,
  onCancelCallback,
  onSubmitCallback,
}: DeleteTopicModalProps) => {
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [confirmTopicName, setConfirmTopicName] = useState<string>("");

  useEffect(() => {
    setMessage({ message: "", variant: "info" });
    setConfirmTopicName("");
    if (!topic) {
      setMessage({ message: failedToDeleteTopic, variant: "error" });
    }
  }, [topic]);

  const onConfirmTopicNameChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmTopicName(e.target.value);
  };

  const getContent = () => {
    return (
      <>
        {topic && topic.numTerms > 0 && (
          <>
            <Typography
              variant="h6"
              gutterBottom
            >{`Topic "${topic.name}" contains ${topic.numTerms} term(s).`}</Typography>
            <Typography variant="subtitle1" gutterBottom>
              {deleteTopicWithTerms}
            </Typography>
            <FormControl fullWidth>
              <TextField
                autoFocus
                required
                variant="outlined"
                key="confirm-delete"
                onChange={onConfirmTopicNameChanged}
              />
            </FormControl>
          </>
        )}

        {topic && topic.numTerms === 0 && (
          <Typography variant="h6" gutterBottom>
            {deleteTopicNoTerms}
          </Typography>
        )}
      </>
    );
  };

  const onDelete = async () => {
    try {
      setMessage({ message: "", variant: "info" });
      if (topic!.numTerms > 0 && confirmTopicName !== topic!.name) {
        setMessage({ message: topicNameDoesNotMatch, variant: "error" });
        return;
      }
      const token = await user.getIdToken();
      await ApiService.deleteTopic(topic!.id.toString(), token);
      setIsBusy(false);
      onSubmitCallback(topic!);
    } catch (e) {
      setMessage({ message: failedToDeleteTopic, variant: "error" });
      setIsBusy(false);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onDelete();
    }
  };

  return (
    <Dialog
      open={show}
      onKeyUp={onKeyUp}
      onClose={onCancelCallback}
      disableRestoreFocus
    >
      <DialogTitle>{`${doDelete} ${topicLabel}: ${topic?.name}?`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ m: 1 }}>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {isBusy ? <LoadingComponent /> : getContent()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={isBusy} onClick={onCancelCallback}>
          {cancel}
        </Button>
        <Button disabled={isBusy} onClick={onDelete}>
          {doDelete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
