import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ApiService from "../../../services/ApiService";
import {
  dashboardNoSchools,
  failedToLoadDashboard,
  manage,
  manageDescription,
} from "../../../services/Messages";
import { School } from "../../../types/schools/school";
import { AuthContext } from "../../../context/AuthContext";
import { LoadingComponent } from "../../../components/LoadingComponent";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { CreateGameCard } from "./CreateGameCard";
import { ManageUsersCard } from "./ManageUsersCard";

export const DashboardSa = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [noSchools, setNoSchools] = useState<boolean>(false);

  const {
    loading: authLoading,
    userCredential,
    changeSchoolId,
  } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async (schoolId: string, token: string) => {
      const response = await ApiService.getSchools(token);
      const schools: School[] = response;

      if (schools.length === 0) {
        changeSchoolId("");
        setNoSchools(true);
      } else {
        const school = schools.find((s) => s.id.toString() === schoolId);
        if (!school) {
          const id = schools[0].id.toString();
          changeSchoolId(id);
        }
      }
    };

    if (!authLoading && userCredential) {
      fetchData(userCredential.schoolId, userCredential.token)
        .catch((e) => {
          setMessage({ message: failedToLoadDashboard, variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authLoading, userCredential, changeSchoolId]);

  const getManageDepartmentsCard = () => {
    return (
      <Box sx={{ boxShadow: 2, height: "100%" }}>
        <Card variant="outlined" sx={{ height: "100%" }}>
          <CardContent>
            <CardActionArea href="/depts" disableRipple disableTouchRipple>
              <Stack spacing={1} alignContent="center" textAlign="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LibraryBooksIcon
                    style={{ fontSize: 40, color: "#22B8DC" }}
                  />
                </Box>
                <Typography variant="h5">{manage}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {manageDescription}
                </Typography>
              </Stack>
            </CardActionArea>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <>
        <Grid size={{ md: 12, sm: 12, xs: 12 }}>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              href={message.href}
              variant={message.variant}
            />
          )}
        </Grid>

        <Grid size={{ md: 12, sm: 12, xs: 12 }}>
          {noSchools && <Alert severity="warning">{dashboardNoSchools}</Alert>}
        </Grid>

        <Grid size={{ md: 6, sm: 12, xs: 12 }}>
          {getManageDepartmentsCard()}
        </Grid>
        <Grid size={{ md: 6, sm: 12, xs: 12 }}>
          <CreateGameCard />
        </Grid>
        <Grid size={{ md: 6, sm: 12, xs: 12 }}>
          <ManageUsersCard />
        </Grid>
      </>
    );
  };

  return <>{loading ? <LoadingComponent /> : getContent()}</>;
};
