import { useEffect, useState, KeyboardEvent } from "react";
import { User } from "firebase/auth";
import { Term } from "../../../types/terms/Term";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardActions,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import ApiService from "../../../services/ApiService";
import { QuestionsUiState } from "../../../types/questions/QuestionsUiState";
import { Question } from "../../../types/questions/Question";
import { LoadingComponent } from "../../../components/LoadingComponent";
import {
  add,
  answerLabel,
  back,
  confirmDeleteQuestion,
  doDelete,
  doEdit,
  enterRequiredValues,
  failedToGetQuestions,
  failedToSaveQuestion,
  optional,
  questionLabel,
  questionsUiStateError,
  save,
  termEditQuestionsTitle,
  wrongAnswersLabel,
} from "../../../services/Messages";
import axios from "axios";

interface ManageTermQuestionsModalProps {
  show: boolean;
  topicId: string;
  term: Term | null;
  user: User;
  onClosedCallback: (numQuestions: number) => void;
}

export const ManageTermQuestionsModal = ({
  show,
  topicId,
  term,
  user,
  onClosedCallback,
}: ManageTermQuestionsModalProps) => {
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [uiState, setUiState] = useState<string>(QuestionsUiState.list);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionId, setQuestionId] = useState<string>("");
  const [questionText, setQuestionText] = useState<string>("");
  const [questionAnswer, setQuestionAnswer] = useState<string>("");
  const [questionWrongAnswer0, setQuestionWrongAnswer0] = useState<string>("");
  const [questionWrongAnswer1, setQuestionWrongAnswer1] = useState<string>("");
  const [questionWrongAnswer2, setQuestionWrongAnswer2] = useState<string>("");

  const setQuestionData = (question: Question) => {
    setQuestionId(question.id.toString());
    setQuestionText(question.text);
    setQuestionAnswer(question.answer);
    if (question.wrongAnswers) {
      if (question.wrongAnswers.length > 0) {
        setQuestionWrongAnswer0(question.wrongAnswers[0]);
      }
      if (question.wrongAnswers.length > 1) {
        setQuestionWrongAnswer1(question.wrongAnswers[1]);
      }
      if (question.wrongAnswers.length > 2) {
        setQuestionWrongAnswer2(question.wrongAnswers[2]);
      }
    }
  };

  const clearQuestion = () => {
    setQuestionId("");
    setQuestionText("");
    setQuestionAnswer("");
    setQuestionWrongAnswer0("");
    setQuestionWrongAnswer1("");
    setQuestionWrongAnswer2("");
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      setIsBusy(true);
      setUiState(QuestionsUiState.list);
      clearQuestion();

      if (term) {
        const token = await user.getIdToken();
        const questions = await ApiService.getTermQuestions(
          topicId,
          term.id.toString(),
          token
        );
        setQuestions(questions);
      }
    };

    fetchQuestions()
      .then(() => {
        setMessage({ message: "", variant: "info" });
        setIsBusy(false);
      })
      .catch((e) => {
        setMessage({ message: failedToGetQuestions, variant: "error" });
        setIsBusy(false);
      });
  }, [term]);

  const onClose = () => {
    setMessage({ message: "", variant: "info" });
    onClosedCallback(questions.length);
  };

  const onAddQuestion = () => {
    clearQuestion();
    setUiState(QuestionsUiState.add);
  };

  const onBack = () => {
    clearQuestion();
    setMessage({ message: "", variant: "info" });
    setUiState(QuestionsUiState.list);
  };

  const onEditQuestion = (question: Question) => {
    setQuestionData(question);
    setUiState(QuestionsUiState.edit);
  };

  const onDeleteQuestion = (question: Question) => {
    setQuestionData(question);
    setUiState(QuestionsUiState.delete);
  };

  const getQuestionsList = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 12, md: 12 }}>
            {message.message.length > 0 && (
              <MessageComponent
                message={message.message}
                variant={message.variant}
              />
            )}
          </Grid>
          {questions.map((question, index) => {
            return (
              <Grid
                size={{ xs: 12, sm: 12, md: 12 }}
                key={`question-grid-${index}`}
              >
                <Card variant="outlined" key={`question-card-${index}`}>
                  <Box sx={{ p: 2 }} key={`question-box-${index}`}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {question.text}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {question.answer}
                    </Typography>
                    {question.wrongAnswers && (
                      <>
                        <Divider />
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {wrongAnswersLabel}
                        </Typography>
                        <Typography component="ul">
                          {question.wrongAnswers.map((wa, index) => {
                            return (
                              <Typography
                                variant="subtitle2"
                                key={`wrong-answer-${index}`}
                                component="li"
                              >
                                {wa}
                              </Typography>
                            );
                          })}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <CardActions>
                    <Button
                      disableRipple
                      size="small"
                      onClick={() => onEditQuestion(question)}
                    >
                      {doEdit}
                    </Button>
                    <Button
                      disableRipple
                      color="secondary"
                      size="small"
                      onClick={() => onDeleteQuestion(question)}
                    >
                      {doDelete}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={onAddQuestion}>
            <AddIcon />
            &nbsp;{add}
          </Button>
        </Box>
      </>
    );
  };

  const onQuestionTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionText(event.target.value);
  };

  const onQuestionAnswerChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionAnswer(event.target.value);
  };

  const onQuestionWrongAnswerChanged = (wrongAnswer: string, index: number) => {
    switch (index) {
      case 0:
        setQuestionWrongAnswer0(wrongAnswer);
        break;
      case 1:
        setQuestionWrongAnswer1(wrongAnswer);
        break;
      case 2:
        setQuestionWrongAnswer2(wrongAnswer);
        break;
    }
  };

  const getAddEditQuestion = () => {
    return (
      <Stack spacing={1}>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            variant={message.variant}
          />
        )}
        <FormControl fullWidth>
          <Stack spacing={1}>
            <FormLabel component="legend">{questionLabel}</FormLabel>
            <TextField
              autoFocus
              variant="outlined"
              required
              value={questionText}
              name="question"
              id="question"
              onChange={onQuestionTextChanged}
            />
            <FormLabel component="legend">{answerLabel}</FormLabel>
            <TextField
              variant="outlined"
              value={questionAnswer}
              name="answer"
              id="answer"
              onChange={onQuestionAnswerChanged}
            />
            <FormLabel component="legend">{`${wrongAnswersLabel} ${optional}`}</FormLabel>
            <TextField
              variant="outlined"
              value={questionWrongAnswer0}
              name="wrongAnswer0"
              id="wrongAnswer0"
              onChange={(e) => onQuestionWrongAnswerChanged(e.target.value, 0)}
            />
            <TextField
              variant="outlined"
              value={questionWrongAnswer1}
              name="wrongAnswer1"
              id="wrongAnswer1"
              onChange={(e) => onQuestionWrongAnswerChanged(e.target.value, 1)}
            />
            <TextField
              variant="outlined"
              value={questionWrongAnswer2}
              name="wrongAnswer2"
              id="wrongAnswer2"
              onChange={(e) => onQuestionWrongAnswerChanged(e.target.value, 2)}
            />
          </Stack>
        </FormControl>

        <Grid container>
          <Grid size={1}>
            <Button variant="contained" onClick={onSave}>
              {save}
            </Button>
          </Grid>
          <Grid size={10}>&nbsp;</Grid>
          <Grid size={1}>
            <Button variant="contained" color="secondary" onClick={onBack}>
              {back}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const getDeleteQuestion = () => {
    return (
      <Stack spacing={1}>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            variant={message.variant}
          />
        )}
        <Typography variant="h6">{confirmDeleteQuestion}</Typography>
        <Typography variant="subtitle1">{questionText}</Typography>
        <Grid container>
          <Grid size={1}>
            <Button variant="contained" onClick={onDelete}>
              {doDelete}
            </Button>
          </Grid>
          <Grid size={10}>&nbsp;</Grid>
          <Grid size={1}>
            <Button variant="contained" color="secondary" onClick={onBack}>
              {back}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const validateQuestion = () => {
    if (questionText.trim().length === 0) {
      setMessage({ message: enterRequiredValues, variant: "error" });
      return false;
    }
    if (questionAnswer.trim().length === 0) {
      setMessage({ message: enterRequiredValues, variant: "error" });
      return false;
    }
    return true;
  };

  const addQuestion = async () => {
    setMessage({ message: "", variant: "info" });

    if (!validateQuestion()) {
      return;
    }

    const validWrongAnswers: string[] = [
      questionWrongAnswer0,
      questionWrongAnswer1,
      questionWrongAnswer2,
    ].filter((w) => w.length > 0);

    let token = await user.getIdToken();
    const response = await ApiService.addQuestion(
      {
        topicId: topicId,
        termId: term!.id.toString(),
        question: questionText,
        answer: questionAnswer,
        wrongAnswers: validWrongAnswers,
      },
      token
    );
    const newQuestions = questions.concat({
      id: response.data["id"],
      text: response.data["text"],
      answer: response.data["answer"],
      wrongAnswers: response.data["wrongAnswers"],
    });
    setQuestions(newQuestions);
    onBack();
  };

  const editQuestion = async () => {
    setMessage({ message: "", variant: "info" });

    if (!validateQuestion()) {
      return;
    }

    const validWrongAnswers: string[] = [
      questionWrongAnswer0,
      questionWrongAnswer1,
      questionWrongAnswer2,
    ].filter((w) => w.length > 0);

    let token = await user.getIdToken();
    const response = await ApiService.updateQuestion(
      questionId,
      {
        topicId: topicId,
        termId: term!.id.toString(),
        question: questionText,
        answer: questionAnswer,
        wrongAnswers: validWrongAnswers,
      },
      token
    );

    const editedQuestion = {
      id: response.data["id"],
      text: response.data["text"],
      answer: response.data["answer"],
      wrongAnswers: response.data["wrongAnswers"],
    };
    const questionIndex = questions.findIndex(
      (q) => q.id === Number(questionId)
    );
    questions[questionIndex] = editedQuestion;
    setQuestions(questions);
    onBack();
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      switch (uiState) {
        case QuestionsUiState.add:
        case QuestionsUiState.edit:
          onSave();
          break;
        case QuestionsUiState.delete:
          onDelete();
          break;
      }
    }
  };

  const onSave = async () => {
    try {
      setIsBusy(true);
      uiState === QuestionsUiState.add
        ? await addQuestion()
        : await editQuestion();
      setIsBusy(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setMessage({ message: error.response.data.error, variant: "error" });
      } else {
        setMessage({ message: failedToSaveQuestion, variant: "error" });
      }
      setIsBusy(false);
    }
  };

  const onDelete = async () => {
    try {
      setMessage({ message: "", variant: "info" });
      setIsBusy(true);
      let token = await user.getIdToken();
      await ApiService.deleteQuestion(
        topicId,
        term!.id.toString(),
        questionId,
        token
      );
      const qs = questions.filter((q) => q.id !== Number(questionId));
      setQuestions(qs);
      setIsBusy(false);
      onBack();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setMessage({ message: error.response.data.error, variant: "error" });
      } else {
        setMessage({ message: failedToSaveQuestion, variant: "error" });
      }
      setIsBusy(false);
    }
  };

  const getContent = () => {
    switch (uiState) {
      case QuestionsUiState.list:
        return <>{getQuestionsList()}</>;
      case QuestionsUiState.add:
      case QuestionsUiState.edit:
        return <>{getAddEditQuestion()}</>;
      case QuestionsUiState.delete:
        return <>{getDeleteQuestion()}</>;
      default:
        return <Typography variant="h6">{questionsUiStateError}</Typography>;
    }
  };

  return (
    <Dialog fullScreen open={show} onKeyUp={onKeyUp} disableRestoreFocus>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6">{termEditQuestionsTitle}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {isBusy ? <LoadingComponent /> : getContent()}
      </DialogContent>
    </Dialog>
  );
};
