import { Alert, AlertColor } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";

export interface MessageProps {
  message: string;
  href?: string;
  variant: AlertColor;
}

export const MessageComponent = ({ message, href, variant }: MessageProps) => {
  return (
    <Grid size={{ xs: 12, sm: 12, md: 12 }}>
      {href && (
        <Alert className="mt-3 mb-3" severity={variant}>
          <Link href={href} color="grey.900">
            {message}
          </Link>
        </Alert>
      )}
      {!href && (
        <Alert className="mt-3 mb-3" severity={variant}>
          {message}
        </Alert>
      )}
    </Grid>
  );
};
