import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { RequireAuth } from "./context/RequireAuth";
import { AuthProvider } from "./context/AuthContext";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { LandingPage } from "./pages/landing/LandingPage";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import { LoginPage } from "./pages/login/LoginPage";
import { DepartmentsPage } from "./pages/departments/DepartmentsPage";
import { TopicsPage } from "./pages/topics/TopicsPage";
import { TermsPage } from "./pages/terms/TermsPage";
import { LogoutPage } from "./pages/LogoutPage";
import { UserProfilePage } from "./pages/profile/UserProfilePage";
import { UsersPage } from "./pages/users/UsersPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4B88F7",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EB376F",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <DashboardPage />
                </RequireAuth>
              }
            />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />

            <Route
              path="depts"
              element={
                <RequireAuth>
                  <DepartmentsPage />
                </RequireAuth>
              }
            />
            <Route
              path="depts/:deptId/topics"
              element={
                <RequireAuth>
                  <TopicsPage />
                </RequireAuth>
              }
            />
            <Route
              path="depts/:deptId/topics/:topicId/terms"
              element={
                <RequireAuth>
                  <TermsPage />
                </RequireAuth>
              }
            />
            <Route
              path="user/profile"
              element={
                <RequireAuth>
                  <UserProfilePage />
                </RequireAuth>
              }
            />
            <Route
              path="users"
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>Page not found</p>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>
        <Footer />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
