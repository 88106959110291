import { useEffect, useState, KeyboardEvent } from "react";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  add,
  all,
  failedToGetTopics,
  doDelete,
  doEdit,
  numberOfTermsLabel,
  termsLabel,
  topicLabel,
  yearsLabel,
  yearLabel,
  topicDeleted,
  topicAdded,
  topicEdited,
  categoryLabel,
  applyFilterLabel,
  clearFilterLabel,
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { Constants } from "../../../Constants";
import { Topic } from "../../../types/topics/Topic";
import { Year } from "../../../types/departments/Year";
import { AddOrEditTopicModal } from "./AddOrEditTopicModal";
import { DeleteTopicModal } from "./DeleteTopicModal";
import { DataTable } from "../../../components/DataTable";

interface TopicsTableProps {
  schoolId: string;
  departmentId: string;
  years: Array<Year>;
  user: User;
}

export const TopicsTable = ({
  schoolId,
  departmentId,
  years,
  user,
}: TopicsTableProps) => {
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: Constants.ITEMSPERPAGE,
    page: 0,
  });
  const [filterYear, setFilterYear] = useState<string>(all);
  const [filterCategory, setFilterCategory] = useState<string>("");

  const [totalTopics, setTotalTopics] = useState<number>(0);
  const [topics, setTopics] = useState<Array<Topic>>([]);
  const [topic, setTopic] = useState<Topic | null>(null);
  const [showAmendTopic, setShowAmendTopic] = useState<boolean>(false);
  const [showDeleteTopic, setShowDeleteTopic] = useState<boolean>(false);

  const navigate = useNavigate();

  const onEditTopic = (topic: Topic) => {
    setMessage({ message: "", variant: "info" });
    setTopic(topic);
    setShowAmendTopic(true);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: topicLabel,
      width: 250,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "category",
      headerName: categoryLabel,
      width: 250,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "years",
      headerName: yearsLabel,
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "numTerms",
      headerName: numberOfTermsLabel,
      width: 80,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "view",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onViewTerms(top);
            }}
          >
            {termsLabel}
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onEditTopic(top);
            }}
          >
            <EditIcon />
            &nbsp;{doEdit}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              onDeleteTopic(top);
            }}
          >
            <DeleteIcon />
            &nbsp;{doDelete}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getTopics(
      paginationModel.page,
      filterYear === all ? undefined : filterYear,
      filterCategory.trim().length === 0 ? undefined : filterCategory
    )
      .then(() => {
        setMessage({ message: "", variant: "info" });
        setDataLoading(false);
      })
      .catch((e) => {
        setMessage({ message: failedToGetTopics, variant: "error" });
        setDataLoading(false);
      });
  }, [departmentId, user, paginationModel.page]);

  const getTopics = async (
    pageIndex: number,
    year: string | undefined = undefined,
    category: string | undefined = undefined
  ) => {
    const token = await user.getIdToken();
    const topics = await ApiService.getTopics(
      departmentId,
      token,
      pageIndex,
      year,
      category
    );
    setTopics(topics.topics);
    setTotalTopics(topics.total);
  };

  const onYearFilterChanged = (event: SelectChangeEvent) => {
    setFilterYear(event.target.value as string);
  };

  const onCategoryFilterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategory(e.target.value);
  };

  const onCategoryKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onApplyFilter();
    }
  };

  const getFilterContent = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <InputLabel id="year-input-label">{yearLabel}</InputLabel>
        <Select
          labelId="year-input-label"
          id="year-label"
          label={yearLabel}
          value={filterYear}
          onChange={onYearFilterChanged}
        >
          <MenuItem key={all} value={all}>
            {all}
          </MenuItem>
          {years.map((year) => {
            return (
              <MenuItem key={year.name} value={year.name}>
                {year.name}
              </MenuItem>
            );
          })}
        </Select>
        &nbsp;
        <FormLabel component="legend">{categoryLabel}</FormLabel>
        <TextField
          id="category-filter"
          variant="outlined"
          value={filterCategory}
          onChange={onCategoryFilterChanged}
          onKeyUp={onCategoryKeyUp}
        />
        &nbsp;
        <Button variant="contained" onClick={onApplyFilter}>
          {applyFilterLabel}
        </Button>
        &nbsp;
        <Button variant="contained" color="secondary" onClick={onClearFilter}>
          {clearFilterLabel}
        </Button>
      </Grid>
    );
  };

  const onApplyFilter = async () => {
    setDataLoading(true);
    setPaginationModel({
      pageSize: Constants.ITEMSPERPAGE,
      page: 0,
    });
    await getTopics(
      0,
      filterYear === all ? undefined : filterYear,
      filterCategory.trim().length === 0 ? undefined : filterCategory
    );
    setDataLoading(false);
  };

  const onClearFilter = async () => {
    const oldFilterYear = filterYear;
    const oldFilterCategory = filterCategory;

    setFilterYear(all);
    setFilterCategory("");
    setPaginationModel({
      pageSize: Constants.ITEMSPERPAGE,
      page: 0,
    });

    if (oldFilterYear !== all || oldFilterCategory.trim().length > 0) {
      setDataLoading(true);
      await getTopics(0);
      setDataLoading(false);
    }
  };

  const onViewTerms = (topic: Topic) => {
    navigate(`/depts/${departmentId!}/topics/${topic.id}/terms`);
  };

  const onDeleteTopic = (topic: Topic) => {
    setMessage({ message: "", variant: "info" });
    setTopic(topic);
    setShowDeleteTopic(true);
  };

  const onCancelDeleteTopic = () => {
    setTopic(null);
    setShowDeleteTopic(false);
  };

  const onTopicDeleted = (topic: Topic) => {
    const ts = topics.filter((t) => t.id !== topic.id);
    setTopics(ts);
    setTopic(null);
    setShowDeleteTopic(false);
    setMessage({ message: topicDeleted, variant: "success" });
  };

  const onAddTopic = () => {
    setMessage({ message: "", variant: "info" });
    setTopic(null);
    setShowAmendTopic(true);
  };

  const onCancelEditTopic = () => {
    setTopic(null);
    setShowAmendTopic(false);
  };

  const onTopicAmended = (topic: Topic, wasAdded: boolean) => {
    let resetTopics = topics.map((t) => ({ ...t, newOrModified: false }));
    if (wasAdded) {
      setTopics([topic, ...resetTopics]);
      setTotalTopics(totalTopics + 1);
      setTopic(null);
      setShowAmendTopic(false);
      setMessage({ message: topicAdded, variant: "success" });
    } else {
      const topicIndex = resetTopics.findIndex((t) => t.id === topic.id);
      resetTopics[topicIndex] = topic;

      setTopics(resetTopics);
      setTopic(null);
      setShowAmendTopic(false);
      setMessage({ message: topicEdited, variant: "success" });
    }
  };

  const showNewTopic = (params: GridRowParams) => {
    return params.row.newOrModified != null && params.row.newOrModified
      ? "highlight"
      : "";
  };

  return (
    <>
      <AddOrEditTopicModal
        show={showAmendTopic}
        topic={topic}
        schoolId={schoolId}
        departmentId={departmentId}
        years={years}
        user={user}
        onSubmitCallback={onTopicAmended}
        onCancelCallback={onCancelEditTopic}
      />

      <DeleteTopicModal
        show={showDeleteTopic}
        topic={topic}
        user={user}
        onSubmitCallback={onTopicDeleted}
        onCancelCallback={onCancelDeleteTopic}
      />

      {message.message.length > 0 && (
        <MessageComponent message={message.message} variant={message.variant} />
      )}

      {getFilterContent()}

      <DataTable
        loading={dataLoading}
        columns={columns}
        rows={topics}
        totalRows={totalTopics}
        getRowClassNameFunc={showNewTopic}
        paginationModel={paginationModel}
        paginationModelChanged={setPaginationModel}
      />

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={onAddTopic}>
          <AddIcon />
          &nbsp;{add}
        </Button>
      </Box>
    </>
  );
};

export default TopicsTable;
