import { AxiosRequestConfig, AxiosResponse } from "axios";
import http from "../HttpCommon";
import { notAuthorised } from "./Messages";
import { School } from "../types/schools/school";
import { SelfUser } from "../types/users/SelfUser";
import { InviteTeacher } from "../types/users/InviteTeacher";
import { AddTopic } from "../types/topics/AddTopic";
import { EditTopic } from "../types/topics/EditTopic";
import { Department } from "../types/departments/Department";
import { TermsPaged } from "../types/terms/TermsPaged";
import { Topic } from "../types/topics/Topic";
import { AddOrEditTerm } from "../types/terms/AddOrEditTerm";
import {
  AddDepartment,
  IAddDepartment,
} from "../types/departments/AddDepartment";
import { EditDepartment } from "../types/departments/EditDepartment";
import { Constants } from "../Constants";
import { UserDataItem } from "../types/users/UserDataItem";
import { SingleDepartment } from "../types/departments/SingleDepartment";
import { DatabaseUsersPaged } from "../types/users/DatabaseUsersPaged";
import { TopicsPaged } from "../types/topics/TopicsPaged";
import { Question } from "../types/questions/Question";
import { AddOrEditQuestion } from "../types/questions/AddOrEditQuestion";

const getConfig = async (
  token: string | undefined
): Promise<AxiosRequestConfig> => {
  if (!token) {
    throw new Error(notAuthorised);
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const getSchools = async (token: string | undefined): Promise<School[]> => {
  let config = await getConfig(token);
  const result = await http.get<School[]>("/schools", config);
  return result.data;
};

const getUsers = async (
  schoolId: string,
  token: string | undefined,
  pageIndex: number
): Promise<DatabaseUsersPaged> => {
  let config = await getConfig(token);
  let offset = pageIndex * Constants.ITEMSPERPAGE;
  const result = await http.get<DatabaseUsersPaged>(
    `/users/${schoolId}?limit=${Constants.ITEMSPERPAGE}&offset=${offset}`,
    config
  );
  return result.data;
};

const getSelfUser = async (token: string | undefined): Promise<SelfUser> => {
  let config = await getConfig(token);
  const result = await http.get<SelfUser>("/users/self", config);
  return result.data;
};

const addOrUpdateSelfData = async (
  data: UserDataItem,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post("/users/self/data", data, config);
};

const deleteSelfData = async (
  name: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/users/self/data?name=${name}`, config);
};

const inviteTeacher = async (
  invite: InviteTeacher,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post("users/teacher/invite", invite, config);
};

const deleteUser = async (
  userId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/users/${userId}`, config);
};

const addDepartment = async (
  department: IAddDepartment
): Promise<AxiosResponse> => {
  let config = await getConfig(department.token);
  return await http.post<AddDepartment>(
    "/departments",
    department.department,
    config
  );
};

const updateDepartment = async (
  departmentId: string,
  department: EditDepartment,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<EditDepartment>(
    `/departments/${departmentId}`,
    department,
    config
  );
};

const getDepartments = async (
  schoolId: string,
  token: string | undefined
): Promise<Department[]> => {
  let config = await getConfig(token);
  let result = await http.get<Department[]>(
    `/schools/${schoolId}/departments`,
    config
  );
  return result.data;
};

const getDepartment = async (
  departmentId: string,
  token: string | undefined
): Promise<SingleDepartment> => {
  let config = await getConfig(token);
  let result = await http.get<SingleDepartment>(
    `/departments/${departmentId}`,
    config
  );
  return result.data;
};

const deleteDepartment = async (
  departmentId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/departments/${departmentId}`, config);
};

const getTopics = async (
  departmentId: string,
  token: string | undefined,
  pageIndex: number,
  year: string | undefined = undefined,
  category: string | undefined = undefined
): Promise<TopicsPaged> => {
  let config = await getConfig(token);
  let offset = pageIndex * Constants.ITEMSPERPAGE;

  let query = `/departments/${departmentId}/topics?limit=${Constants.ITEMSPERPAGE}&offset=${offset}`;
  if (year != null) {
    query += `&year=${year}`;
  }
  if (category != null) {
    query += `&category=${category}`;
  }

  const result = await http.get<TopicsPaged>(query, config);
  return result.data;
};

const getTopic = async (
  topicId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.get<Topic>(`/topics/${topicId}`, config);
};

const addTopic = async (
  topic: AddTopic,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post<AddTopic>("/topics", topic, config);
};

const updateTopic = async (
  topicId: string,
  topic: EditTopic,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<EditTopic>(`/topics/${topicId}`, topic, config);
};

const deleteTopic = async (
  topicId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/topics/${topicId}`, config);
};

const getTerms = async (
  topicId: string,
  token: string | undefined,
  pageIndex: number
): Promise<TermsPaged> => {
  let config = await getConfig(token);
  let offset = pageIndex * Constants.ITEMSPERPAGE;
  const result = await http.get<TermsPaged>(
    `/topics/${topicId}/terms?limit=${Constants.ITEMSPERPAGE}&offset=${offset}`,
    config
  );
  return result.data;
};

const addTerm = async (
  term: AddOrEditTerm,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post<AddOrEditTerm>("/terms", term, config);
};

const updateTerm = async (
  termId: string,
  term: AddOrEditTerm,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<AddOrEditTerm>(`/terms/${termId}`, term, config);
};

const deleteTerm = async (
  topicId: string,
  termId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/topics/${topicId}/terms/${termId}`, config);
};

const getTermQuestions = async (
  topicId: string,
  termId: string,
  token: string | undefined
): Promise<Question[]> => {
  let config = await getConfig(token);
  const result = await http.get<Question[]>(
    `/topics/${topicId}/terms/${termId}/questions`,
    config
  );
  return result.data;
};

const addQuestion = async (
  question: AddOrEditQuestion,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post<AddOrEditQuestion>("/questions", question, config);
};

const updateQuestion = async (
  questionId: string,
  question: AddOrEditQuestion,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<AddOrEditQuestion>(
    `/questions/${questionId}`,
    question,
    config
  );
};

const deleteQuestion = async (
  topicId: string,
  termId: string,
  questionId: string,
  token: string | undefined
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(
    `/topics/${topicId}/terms/${termId}/questions/${questionId}`,
    config
  );
};

const ApiService = {
  getSchools,
  getUsers,
  getSelfUser,
  addOrUpdateSelfData,
  deleteSelfData,
  inviteTeacher,
  deleteUser,
  addDepartment,
  updateDepartment,
  getDepartments,
  getDepartment,
  deleteDepartment,
  getTopics,
  getTopic,
  addTopic,
  updateTopic,
  deleteTopic,
  getTerms,
  addTerm,
  updateTerm,
  deleteTerm,
  getTermQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
};

export default ApiService;
