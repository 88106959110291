import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  gridClasses,
} from "@mui/x-data-grid";
import { Constants } from "../Constants";

interface DataTableProps {
  loading: boolean;
  columns: GridColDef[];
  rows: any[];
  totalRows: number;
  paginationModel: GridPaginationModel;
  getRowClassNameFunc: (params: GridRowParams) => string;
  paginationModelChanged: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void;
}

export const DataTable = ({
  loading,
  columns,
  paginationModel,
  getRowClassNameFunc,
  paginationModelChanged,
  rows,
  totalRows,
}: DataTableProps) => {
  return (
    <DataGrid
      sx={{
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          outline: "transparent",
        },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
          {
            outline: "none",
          },
        ".highlight": {
          bgcolor: "#FFFFC5",
          "&:hover": {
            bgcolor: "#FFFFC5",
          },
        },
      }}
      disableRowSelectionOnClick
      disableColumnMenu={true}
      columns={columns}
      loading={loading}
      rows={rows}
      rowCount={totalRows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={paginationModelChanged}
      pageSizeOptions={[Constants.ITEMSPERPAGE]}
      getRowClassName={getRowClassNameFunc}
      onCellClick={(params, event) => {
        event.stopPropagation();
      }}
      onRowClick={(params, event) => {
        event.stopPropagation();
      }}
    />
  );
};
